import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SubscribeAll from './SubscribeAll';
import Footer from './Footer';
import axios from './axiosConfig';  // Import axios for API calls

const UseCaseDetail = () => {
  const { id } = useParams();  // Get the ID from the URL params
  const [useCase, setUseCase] = useState(null);  // State to hold the fetched use case
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch the use case from the API when the component mounts
  useEffect(() => {
    const fetchUseCase = async () => {
      try {
        const response = await axios.get(`/api/usecases/${id}`);  // Fetch the specific use case by ID
        setUseCase(response.data);  // Set the fetched use case
        setLoading(false);  // Loading done
      } catch (err) {
        setError('Failed to fetch the use case');
        setLoading(false);
      }
    };

    fetchUseCase();
  }, [id]);  // Re-fetch if the ID changes

  // Hooks for typing effect
  const sentences = [
    `Search: ${useCase?.search}\n\n`, 
    `Results:\n${Object.entries(useCase?.search_result || {}).map(([title, result]) => `${title}: ${result}`).join('\n')}\n\n`, 
    `Summarized Result:\n${useCase?.summarize_result}\n\n`, 
    `Question: ${useCase?.question}\nAnswer: ${useCase?.answer}\n`, 
  ];

  const [currentText, setCurrentText] = useState("");
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);

  // Effect for controlling the typing logic
  useEffect(() => {
    let timeout;
    if (isTyping && charIndex < sentences[currentSentenceIndex]?.length) {
      timeout = setTimeout(() => {
        setCurrentText((prev) => prev + sentences[currentSentenceIndex][charIndex]);
        setCharIndex(charIndex + 1);
      }, 50);
    } else if (charIndex === sentences[currentSentenceIndex]?.length && currentSentenceIndex < sentences.length - 1) {
      // Move to the next sentence after the current one is done
      setIsTyping(false);
      timeout = setTimeout(() => {
        setCharIndex(0);
        setCurrentSentenceIndex(currentSentenceIndex + 1); // Move to next sentence
        setIsTyping(true);
      }, 1000); // Small pause between sentences
    } else if (currentSentenceIndex === sentences.length - 1 && charIndex === sentences[currentSentenceIndex]?.length) {
      // If all sentences are finished, reset everything
      setIsTyping(false);
      timeout = setTimeout(() => {
        setCurrentText(""); // Clear text
        setCharIndex(0);
        setCurrentSentenceIndex(0); // Reset to first sentence
        setIsTyping(true);
      }, 2000); // Pause for 2 seconds after all sentences are typed
    }

    // Cleanup timeout on unmount
    return () => clearTimeout(timeout);
  }, [isTyping, charIndex, sentences, currentSentenceIndex]);

  // Handle loading and error states without conditional hooks
  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!useCase) {
    return <p>Use Case not found</p>;
  }

  return (
    <div className="container mx-auto px-4 py-12">
      <div className="text-sm text-gray-500 mb-2">Use Cases &gt; {useCase.title}</div>
      <h1 className="text-4xl font-bold mb-6">{useCase.title}</h1>
      <p className="text-gray-600 mb-6">{useCase.description}</p>
      <img src={useCase.image} alt={useCase.title} className="w-full h-64 object-cover rounded-lg mb-6" />
      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Case Details</h2>
        <p className="whitespace-pre-line text-gray-600">{useCase.content}</p>
      </div>
      <div className="bg-gray-100 p-4 rounded-lg shadow-md whitespace-pre-line text-gray-700">
        {currentText}
        <span className="blinking-cursor"> </span> {/* Blinking cursor */}
      </div>
      
      {/* Call-to-Action Section */}
      <SubscribeAll />

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default UseCaseDetail;
