import React from "react";
import { useNavigate } from "react-router-dom";

const SubscribeLanding = () => {
  const navigate = useNavigate();

  const handleSubscribe = () => {
    // Redirect to the subscription page
    navigate("/subscription");
  };

  return (
  
      <button
        className="bg-black text-white px-6 py-3 rounded-md text-lg"
        onClick={handleSubscribe} // Redirect on click
      >
        Subscribe Now
      </button>
  );
};

export default SubscribeLanding;
