import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const PaymentSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Parse query parameters (if any) appended by Beam
  const searchParams = new URLSearchParams(location.search);
  const status = searchParams.get("status") || "unknown";

  useEffect(() => {
    // Redirect to login after 3 seconds
    const timer = setTimeout(() => {
      navigate("/login");
    }, 3000);

    return () => clearTimeout(timer); // Cleanup timeout on unmount
  }, [navigate]);

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>Payment Successful</h1>
      <p>Thank you for your payment!</p>
      <p>Status: {status}</p>
      <p>You will be redirected to the login page shortly...</p>
    </div>
  );
};

export default PaymentSuccess;
