import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const SubscribeAll = () => {
  // State to manage modal visibility
  const [showModal, setShowModal] = useState(false);

  // Navigation hook
  const navigate = useNavigate();

  const handleSubscribe = () => {
    // Redirect to the subscription page
    navigate("/subscription");
  };

  return (
    <>
      <section className="py-20 text-center">
        <div className="container mx-auto px-4">
          <h3 className="text-lg text-gray-600 mb-4">Get started</h3>
          <h2 className="text-5xl font-bold mb-6">
            Subscribe to Your Plan
          </h2>
          <p className="text-xl mb-8">Choose your subscription to begin.</p>
          {/* Button to trigger the modal */}
          <button
            className="bg-black text-white px-6 py-3 rounded-md text-lg flex items-center justify-center mx-auto"
            onClick={(handleSubscribe) } 
          >
            Subscribe Now
          </button>
        </div>
      </section>

      
    </>
  );
};

export default SubscribeAll;
