import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from './axiosConfig'; // Import axios for making API requests
import SubscribeAll from './SubscribeAll';
import Footer from './Footer';

const BlogDetail = () => {
  const { id } = useParams(); // Get blog id from the URL

  // State to hold the blog post data
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch blog post data when component mounts
  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        const response = await axios.get(`/api/blog/${id}`); // Fetch blog post by ID from API
        setPost(response.data); // Set the fetched post in the state
        setLoading(false); // Set loading to false after fetching data
      } catch (err) {
        setError('Failed to fetch blog post');
        setLoading(false); // Stop loading
      }
    };
    fetchBlogPost();
  }, [id]); // Dependency array ensures the effect runs when the ID changes

  // Handle loading and error states
  if (loading) return <p className="text-center text-gray-500">Loading...</p>;
  if (error) return <p className="text-center text-red-500">{error}</p>;

  if (!post) return <div className="text-center text-gray-500">Blog post not found</div>;

  return (
    <div className="container mx-auto px-4 lg:px-20 py-12">
      {/* Breadcrumb */}
      <div className="text-sm text-gray-500 mb-4">
        <Link to="/blog" className="text-blue-600 hover:underline">
          Blog
        </Link>{' '}
        &gt; {post.title}
      </div>

      {/* Blog Title */}
      <h1 className="text-3xl md:text-4xl font-bold mb-6">{post.title}</h1>

      {/* Blog Image */}
      {post.image && (
        <img
          src={post.image}
          alt={post.title}
          className="w-full h-48 md:h-64 object-cover rounded-lg mb-6"
        />
      )}

      {/* Blog Content */}
      <p className="text-gray-600 mb-6">{post.content}</p>

      {/* Author and Date */}
      <div className="text-gray-500 text-sm mb-6">
        {post.author || 'Anonymous'} -{' '}
        {post.date ? new Date(post.date).toLocaleDateString() : 'No date available'}
      </div>

      {/* Quote Section */}
      {post.quotes && post.quotes.length > 0 && (
        <div className="mb-6">
          {post.quotes.map((quote, index) => (
            <div key={index} className="border-l-4 border-gray-300 pl-4 mb-4">
              <p className="italic text-lg">"{quote.quote}"</p>
              <p className="text-gray-500 text-sm mt-2">- {quote.author || 'Unknown'}</p>
            </div>
          ))}
        </div>
      )}

      {/* Additional Content Section */}
      {post.additionalContent && <p className="text-gray-600 mb-6">{post.additionalContent}</p>}

      {/* Reference Section */}
      {post.reference && (
        <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-8">
          <h3 className="text-xl font-semibold mb-2">Reference</h3>
          <p className="text-gray-600 mb-2">
            <strong>{post.reference.newsHeadline}</strong>
          </p>
          <a
            href={post.reference.newsLink}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:underline break-words"
          >
            {post.reference.newsLink}
          </a>
        </div>
      )}

      {/* Call-to-Action Section */}
      <SubscribeAll />

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default BlogDetail;
