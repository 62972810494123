import React from "react";

const PrivacyPolicy = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>นโยบายความเป็นส่วนตัว</h1>
      <p style={styles.subtitle}>เว็บแอป KHORN</p>
      <p style={styles.lastUpdated}>ปรับปรุงล่าสุด: 21 พฤศจิกายน 2567</p>
      <div style={styles.policyBox}>
        <p style={styles.intro}>
          KHORN ให้ความสำคัญกับการคุ้มครองข้อมูลส่วนบุคคลของผู้ใช้ทุกท่าน
          เราได้จัดทำนโยบายความเป็นส่วนตัวฉบับนี้เพื่ออธิบายวิธีการเก็บรวบรวม
          ใช้งาน และปกป้องข้อมูลของท่านเมื่อใช้งานเว็บแอปของเรา
        </p>

        <h2 style={styles.sectionTitle}>1. ข้อมูลที่เราเก็บรวบรวม</h2>
        <p style={styles.text}>
          เมื่อใช้งาน KHORN เราอาจเก็บรวบรวมข้อมูลดังต่อไปนี้:
        </p>
        <ul style={styles.list}>
          <li>
            <strong>ข้อมูลที่ท่านให้โดยตรง:</strong> เช่น ชื่อ, อีเมล, รหัสผ่าน,
            และข้อมูลอื่น ๆ ในกระบวนการสมัคร
          </li>
          <li>
            <strong>ข้อมูลเกี่ยวกับเอกสาร:</strong> เช่น เอกสารที่ท่านอัปโหลดเพื่อการวิจัยทางกฎหมาย
            หรือเพื่อการใช้งานอื่น ๆ
          </li>
          <li>
            <strong>ข้อมูลการใช้งาน:</strong> เช่น ประวัติการค้นหา, การสนทนากับเอกสาร,
            และกิจกรรมอื่น ๆ บนเว็บแอป
          </li>
          <li>
            <strong>ข้อมูลทางเทคนิค:</strong> เช่น ที่อยู่ IP, ประเภทเบราว์เซอร์, ข้อมูลอุปกรณ์
          </li>
        </ul>

        <h2 style={styles.sectionTitle}>2. วัตถุประสงค์ในการใช้ข้อมูล</h2>
        <p style={styles.text}>
          เราใช้ข้อมูลของท่านเพื่อวัตถุประสงค์ดังต่อไปนี้:
        </p>
        <ul style={styles.list}>
          <li>
            <strong>การให้บริการ:</strong> เช่น ระบบค้นหาข้อมูลกฎหมาย, การสนทนากับเอกสาร,
            และการจัดการเอกสาร
          </li>
          <li>
            <strong>พัฒนาและปรับปรุงบริการ:</strong> เพื่อเพิ่มประสิทธิภาพของระบบและบริการ
          </li>
          <li>
            <strong>รักษาความปลอดภัย:</strong> ตรวจสอบและป้องกันการเข้าถึงข้อมูลโดยไม่ได้รับอนุญาต
          </li>
          <li>
            <strong>การติดต่อสื่อสาร:</strong> เช่น แจ้งอัปเดต, ข้อเสนอพิเศษ, หรือการสนับสนุนด้านเทคนิค
          </li>
        </ul>

        <h2 style={styles.sectionTitle}>3. การเปิดเผยข้อมูลส่วนบุคคล</h2>
        <p style={styles.text}>
          KHORN จะไม่เปิดเผยข้อมูลส่วนบุคคลของท่าน ยกเว้นในกรณีต่อไปนี้:
        </p>
        <ul style={styles.list}>
          <li>เพื่อปฏิบัติตามกฎหมายหรือคำสั่งศาล</li>
          <li>เพื่อปกป้องสิทธิหรือทรัพย์สินของ KHORN</li>
          <li>เมื่อได้รับความยินยอมจากท่าน</li>
        </ul>

        <h2 style={styles.sectionTitle}>4. การเก็บรักษาข้อมูล</h2>
        <p style={styles.text}>
          เราเก็บรักษาข้อมูลส่วนบุคคลของท่านในระบบที่มีมาตรการรักษาความปลอดภัยสูง เช่น
          การเข้ารหัสข้อมูลและการควบคุมการเข้าถึงอย่างเคร่งครัด
        </p>

        <h2 style={styles.sectionTitle}>5. สิทธิของผู้ใช้</h2>
        <p style={styles.text}>
          ท่านมีสิทธิ์ดำเนินการดังนี้:
        </p>
        <ul style={styles.list}>
          <li>สิทธิในการขอเข้าถึงและขอสำเนาข้อมูลส่วนบุคคลของท่าน</li>
          <li>สิทธิในการขอแก้ไขข้อมูลที่ไม่ถูกต้องหรือไม่สมบูรณ์</li>
          <li>สิทธิในการขอลบข้อมูลส่วนบุคคล (ในกรณีที่ไม่ขัดกับข้อกำหนดทางกฎหมาย)</li>
          <li>สิทธิในการปฏิเสธการใช้งานข้อมูลส่วนบุคคลบางประเภท</li>
        </ul>
        <p style={styles.text}>
          หากท่านต้องการใช้สิทธิ์เหล่านี้ กรุณาติดต่อเราผ่านทาง [ช่องทางการติดต่อของคุณ]
        </p>

        <h2 style={styles.sectionTitle}>6. การใช้คุกกี้</h2>
        <p style={styles.text}>
          KHORN อาจใช้คุกกี้เพื่อปรับปรุงประสบการณ์การใช้งานและการวิเคราะห์ระบบ
          ท่านสามารถเลือกปิดการใช้งานคุกกี้ผ่านเบราว์เซอร์ของท่านได้
        </p>

        <h2 style={styles.sectionTitle}>7. การเปลี่ยนแปลงนโยบายความเป็นส่วนตัว</h2>
        <p style={styles.text}>
          KHORN อาจปรับปรุงนโยบายความเป็นส่วนตัวนี้เป็นครั้งคราว
          โดยจะแจ้งให้ทราบถึงการเปลี่ยนแปลงที่สำคัญผ่านทางอีเมลหรือการแจ้งเตือนในระบบ
        </p>

        <h2 style={styles.sectionTitle}>8. การติดต่อเรา</h2>
        <p style={styles.text}>
          หากท่านมีคำถามหรือข้อกังวลเกี่ยวกับนโยบายความเป็นส่วนตัว กรุณาติดต่อเรา:
        </p>
        <ul style={styles.list}>
          <li><strong>อีเมล:</strong> [contact@khorn-ai.com]</li>
          <li><strong>เบอร์โทรศัพท์:</strong> [081-811-8772]</li>
        </ul>
      </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: "800px",
    margin: "auto",
    padding: "20px",
    fontFamily: "'Arial', sans-serif",
    lineHeight: "1.8",
  },
  title: {
    textAlign: "center",
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  subtitle: {
    textAlign: "center",
    fontSize: "18px",
    marginBottom: "5px",
    color: "#555",
  },
  lastUpdated: {
    textAlign: "center",
    fontSize: "14px",
    marginBottom: "20px",
    color: "#888",
  },
  policyBox: {
    background: "#f9f9f9",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "5px",
  },
  intro: {
    fontSize: "16px",
    marginBottom: "15px",
  },
  sectionTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    marginTop: "20px",
    marginBottom: "10px",
  },
  text: {
    fontSize: "16px",
    marginBottom: "15px",
  },
  list: {
    paddingLeft: "20px",
    marginTop: "10px",
  },
};

export default PrivacyPolicy;
