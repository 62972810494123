import React from 'react';
import { Link } from 'react-router-dom';

const NavigationBar = () => {
  return (
    <header className="flex items-center justify-between px-4 py-4 border rounded-xl mt-4 mx-4 md:mx-20">
      {/* Logo */}
      <Link to="/" className="text-2xl font-bold">
        K
      </Link>

      {/* Navigation Menu */}
      <nav className="hidden md:block">
        <ul className="flex space-x-6">
          <li>
            <Link to="/usecase" className="text-gray-600 hover:text-gray-900 text-sm">
              Use Cases
            </Link>
          </li>
          <li>
            <Link to="/data" className="text-gray-600 hover:text-gray-900 text-sm">
              Data
            </Link>
          </li>
          <li>
            <Link to="/subscription" className="text-gray-600 hover:text-gray-900 text-sm">
              Pricing
            </Link>
          </li>
          <li>
            <Link to="/blog" className="text-gray-600 hover:text-gray-900 text-sm">
              News
            </Link>
          </li>
          {/* External link to the subscription page */}
          <li>
          <Link
            to="/subscription"
            className="bg-white border border-black text-black font-bold px-4 py-2 rounded-md hover:bg-gray-800 hover:text-white text-sm"
          >
            SIGNUP
          </Link>
        </li>
          {/* External link to the login page */}
          <li>
            <a
              href="https://app.khorn-ai.com"
              className="bg-black text-white px-4 py-2 rounded-md hover:bg-gray-800 text-sm"
            >
              LOGIN
            </a>
          </li>
        </ul>
      </nav>

      {/* Mobile Navigation Menu */}
      <nav className="block md:hidden">
        <ul className="flex space-x-4">
          <li>
            <Link to="/usecase" className="text-gray-600 hover:text-gray-900 text-sm">
              Cases
            </Link>
          </li>
          <li>
            <Link to="/data" className="text-gray-600 hover:text-gray-900 text-sm">
              Data
            </Link>
          </li>
          <li>
            <Link to="/subscription" className="text-gray-600 hover:text-gray-900 text-sm">
              Pricing
            </Link>
          </li>
          <li>
            <Link to="/blog" className="text-gray-600 hover:text-gray-900 text-sm">
              Blog
            </Link>
          </li>
          {/* External link to the login page */}
          <li>
            <a
              href="https://app.khorn-ai.com"
              className="bg-black text-white px-3 py-1 rounded-md hover:bg-gray-800 text-sm"
            >
              LOGIN
            </a>
          </li>
  
        </ul>
      </nav>
    </header>
  );
};

export default NavigationBar;
