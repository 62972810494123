import React from "react";
import { Check } from "lucide-react";
import { useNavigate } from "react-router-dom";

export default function PlanPage() {
  const navigate = useNavigate();

  const plans = [
    {
      name: "Free",
      planKey: "free",
      commitment: null,
      price: "0",
      description: "For legal hobby",
      features: [
        "1 user",
        "1 Project",
        "KHORN (limited)",
        "Basic support",
      ],
    },
    {
      name: "Pro (Monthly)",
      planKey: "pro",
      commitment: "monthly",
      price: "899",
      description: "Ideal for professionals",
      features: [
        "1 user",
        "Unlimited Projects",
        "KHORN Search",
        "KHORN Research Assistant",
        "KHORN Review Assistant",
        "24/7 support",
      ],
    },
    {
      name: "Pro (Yearly)",
      planKey: "pro",
      commitment: "yearly",
      price: "449",
      totalYearly: "5388",
      description: "Ideal for professionals (save 50%)",
      features: [
        "1 user",
        "Unlimited Projects",
        "KHORN Search",
        "KHORN Research Assistant",
        "KHORN Review Assistant",
        "24/7 support",
      ],
      highlight: true,
    },
  ];

  const handleSelectPlan = (planKey, commitment) => {
    navigate("/signup", {
      state: { plan: planKey, isYearly: commitment === "yearly" },
    });
  };

  return (
    <div className="py-12 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Choose the right plan for you
          </h2>
          <p className="mt-4 text-xl text-gray-600">
            Simple, transparent pricing that grows with you
          </p>
          <p className="mt-2 text-xl text-green-600 font-semibold">
            Save 50% with yearly subscriptions!
          </p>
        </div>

        <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3">
          {plans.map((plan) => (
            <div
              key={`${plan.planKey}-${plan.commitment || "free"}`}
              className={`relative flex flex-col justify-between border rounded-lg shadow-md p-6 ${
                plan.highlight ? "border-green-600 shadow-lg" : ""
              }`}
            >
              <div className="mb-4">
                <h3 className="text-2xl font-semibold">{plan.name}</h3>
                <p className="text-sm text-gray-600">{plan.description}</p>
              </div>

              <div className="text-center mb-4">
                <span className="text-4xl font-extrabold">{plan.price} THB</span>
                {plan.commitment && (
                  <span className="text-base font-medium text-gray-500">
                    /month
                  </span>
                )}
                {plan.totalYearly && (
                  <p className="mt-2 text-sm text-gray-600">
                    Total Yearly:{" "}
                    <span className="font-bold text-gray-800">
                      {plan.totalYearly} THB
                    </span>
                  </p>
                )}
              </div>

              <ul className="mb-6 space-y-2">
                {plan.features.map((feature) => (
                  <li key={feature} className="flex items-center text-gray-700">
                    <Check className="h-5 w-5 text-green-500 mr-2" />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>

              <button
                onClick={() => handleSelectPlan(plan.planKey, plan.commitment)}
                className={`w-full px-4 py-2 text-white rounded-md shadow-sm ${
                  plan.highlight
                    ? "bg-green-600 hover:bg-green-500"
                    : "bg-gray-600 hover:bg-gray-700"
                }`}
              >
                {plan.name === "Free" ? "Get Started for Free" : "Subscribe Now"}
              </button>

              {plan.highlight && (
                <div className="absolute top-0 right-0 px-3 py-1 bg-green-500 text-white text-sm font-semibold rounded-bl rounded-tr">
                  Save 50%
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
