import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SubscribeAll from './SubscribeAll';
import Footer from './Footer';
import axios from './axiosConfig'; 

const BlogPage = () => {
  // State to hold blog posts
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch data from API when component mounts
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        // Make API call to fetch blogs
        const response = await axios.get('/api/blogs');
        setBlogPosts(response.data); // Set fetched blogs in state
        setLoading(false); // Set loading to false once data is loaded
      } catch (err) {
        setError('Failed to fetch blogs');
        setLoading(false);
      }
    };
    fetchBlogs();
  }, []);

  // Handle loading and error states
  if (loading) return <p className="text-center text-gray-500">Loading...</p>;
  if (error) return <p className="text-center text-red-500">{error}</p>;

  return (
    <div className="container mx-auto px-4 lg:px-20 py-12">
      <h1 className="text-5xl font-bold mb-12 text-center">Blog</h1>
      
      {/* Handle empty blog list */}
      {blogPosts.length === 0 ? (
        <p className="text-center text-gray-500">No blog posts available.</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogPosts.map((post) => (
            <Link to={`/blog/${post._id}`} key={post._id}>
              <div className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow">
                {/* Blog Image */}
                <div className="relative">
                  <img
                    src={post.image || 'https://via.placeholder.com/300'} // Fallback image
                    alt={post.title || 'Blog post'}
                    className="w-full h-48 object-cover"
                  />
                  {post.tag && (
                    <div className="absolute top-2 left-2 bg-black text-white text-xs px-2 py-1 rounded-full">
                      {post.tag}
                    </div>
                  )}
                </div>

                {/* Blog Content */}
                <div className="p-6">
                  <h2 className="text-2xl font-bold mb-4">{post.title}</h2>
                  <p className="text-gray-600 mb-4 line-clamp-3">{post.content.substring(0, 100)}...</p> {/* Truncated content */}
                  
                  {/* Author and Date */}
                  <div className="flex items-center space-x-2">
                    <div className="bg-gray-200 p-2 rounded-full">
                      {/* Author avatar placeholder */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 14l9-5-9-5-9 5 9 5z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 14v10M12 14l-9-5 9-5m0 0l9 5-9 5"
                        />
                      </svg>
                    </div>
                    <div>
                      <p className="text-sm font-semibold">{post.author || 'Anonymous'}</p>
                      <p className="text-sm text-gray-500">
                        {post.date ? new Date(post.date).toLocaleDateString() : 'No date available'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}

      {/* Call-to-Action Section */}
      <SubscribeAll />

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default BlogPage;
